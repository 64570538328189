<template>
 <v-container >
    <v-layout wrap justify-center>
      <v-btn href="/monitoring/summary" target="_blank">
        <v-img src="@/assets/images/summary_green.svg" alt="모니터링" height="200px" width="200px" />
      </v-btn>

      <v-btn href="/monitoring/cardroominfo">
        <v-img src="@/assets/images/barometer_green.svg" alt="지표 조회" height="200px" width="200px" />
      </v-btn>
    </v-layout>

    <v-layout wrap justify-center>
      <v-btn href="/dailyrecord/totalevents" v-if="service.dailyrecord">
        <v-img src="@/assets/images/asset_green.svg" alt="관리 일지" height="200px" width="200px" />
      </v-btn>

      <v-btn @click="control()" v-if="service.fancontrol">
        <v-img src="@/assets/images/control_green.svg" alt="환기 제어" height="200px" width="200px" />
      </v-btn>
      
      <v-btn href="/deodorizer/deodorizerHome" v-if="service.deodorizer">
        <v-img src="@/assets/images/deodorizer_green.svg" alt="악취 제어" height="200px" width="200px" />
      </v-btn>

        
      <v-btn href="/esg/monitorEsg2" v-if="service.esg">
        <v-img src="@/assets/images/asset_esg.svg" alt="esg" height="200px" width="200px" />
      </v-btn>
    </v-layout>
  
    <v-hover v-slot="{hover}" v-show="$getters.userInfo.grade < 'UT04'">
      <v-btn fab outlined class="monitor_btn" alt="모니터링 현황판" href="/monitoring/allmonitor">
        <v-icon color=#3a4f3f regular>
          mdi-sitemap
        </v-icon>
      
        <v-expand-transition>
          <div v-if="hover" class="monitor_hover" transition="scale-transition">
            <div style="padding:5px; text-align:right;">통합<br/>모니터링</div>
          </div>
        </v-expand-transition>
      </v-btn>
    </v-hover>
    

    <v-hover v-slot="{hover}" v-show="$getters.userInfo.grade < 'UT04'">
      <v-btn fab outlined class="mobile_btn" alt="모니터링 현황판" href="/monitoring/cardroomtablet">
        <v-icon color=white regular>
          mdi-monitor
        </v-icon>
      
        <v-expand-transition>
          <div v-if="hover" class="hover" transition="scale-transition" >
            <div style="padding:5px; text-align:right;">모니터링<br/>현황판</div>
          </div>
        </v-expand-transition>
      </v-btn>
    </v-hover>

 </v-container>
</template>

<script>
  import Apis from '@/api/apis'
  export default {
    name: 'HomeCard',
    created() {
      this.listAccesableServices();
      Apis.getIpClient((res) => {
        // console.log(res);  
        let client = res
        Apis.pageCount({ //페이지뷰 카운터
          page_name: `${client.city}/${client.country}/${client.region}/${client.loc}/${client.org}/${client.postal}/${client.timezone}`,
          url: this.$route.path,
          ip: client.ip,
          division: "pigro",
        },() => {  // 정상처리
          // console.log("pageCount 호출",res);
        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("pageCount 호출 오류",err);
        });
      }).catch( (err) => {  // API 오류 처리
          console.log("getIpClient 호출 오류",err)
      }) 
    },
    data: () => ({
      service:{}
    }),

    methods: {
      listAccesableServices(){
        this.$store.commit("resMessage","");

        Apis.listAccesableServices({
        },(res) => {  
          // console.log(res.data);
          this.service = res.data;
        }).catch( (err) => {  // API 오류 처리
            console.log("listAccesableServices API 호출 오류",err)
            alert(err);
        }) 
      },
      summary() {
        this.$router.push("/summary")
      },
      barometer() {
        this.$router.push("/barometer")        
      },
      asset() {
        this.$router.push("/asset")                
      },
      control() {
        // console.log("goto control")
        // this.$router.push("/setinfo")                        

        // this.$router.push("/device/settingcontrol")
        this.$router.push("/control/controlsummary")                               
      },
      // deodorizer(){
      //   this.$router.push("/deodorizer/deodorizermonitoring") 
      //   // this.$router.push("/deodorizer/deodorizerset") 

      // }

    },
  }
</script>
<style lang="scss" scoped>
.home{
  display:flex;
  justify-content: center;
  flex-wrap: wrap; 
  margin:0 auto;
  width:700px;
}
.v-btn {
  display: flex;
  width: auto !important;
  height: auto !important;
  align-items: bottom !important;
  height: 47px;
  border: none !important;
  background-color: white !important;
  cursor: pointer;
  margin: 20px !important;
  padding: 0px !important;
}
.monitor_btn{
  position:fixed;
  right:5px;
  bottom: 75px;
  height:50px !important;
  width:50px !important;
  line-height:50px !important;
  // color:#3a4f3f !important;
  border:2px solid #3a4f3f !important;
  background-color: white !important;
}
.monitor_btn:hover{
  background-color: #cee1d6 !important;
}
.monitor_hover{
  position:fixed;
  right:20px;
  bottom: 150px;
  background-color: #658774;
  color:white;
  border-radius: 10px;
}

.mobile_btn{
  position:fixed;
  right:5px;
  bottom: 20px;
  height:50px !important;
  width:50px !important;
  line-height:50px !important;
  color:white !important;
  background-color: #3a4f3f !important;
}

.hover{
  position:fixed;
  right:20px;
  bottom: 95px;
  background-color: #658774;
  color:white;
  border-radius: 10px;
}


</style>
